import { EyeOutlined, FileDoneOutlined, RedoOutlined, SendOutlined } from "@ant-design/icons";
import { getDefaultSortOrder, FilterDropdown, List, useSelect, useTable } from "@refinedev/antd";
import { useModal, useUpdate } from "@refinedev/core";
import { Button, Checkbox, Col, DatePicker, Divider, Input, Modal, Row, Select, Space, Table, Popover, Typography } from "antd";
import dayjs from "dayjs";
import React from 'react';


const { Title, Text } = Typography;

const to2 = (num) => num < 10 ? `0${ num }` : num;

const formatDate = (textDate) => {

	let date = new Date(textDate);
	return `${ date.getFullYear() }-${ to2(date.getMonth() + 1) }-${ to2(date.getDate()) } ${ to2(date.getHours()) }:${ to2(date.getMinutes()) }`;

}

export const JobsList = () => {


	 const { visible, show, close } = useModal();


	const now = new Date();
	let aMonthAgo = new Date();

	aMonthAgo.setMonth(aMonthAgo.getMonth() - 1);

	const [ currentDisplayingJob, setCurrentDisplayingJob ] = React.useState(null);

	const [dateFilters, setDateFilters] = React.useState([dayjs(aMonthAgo), dayjs(now)]);

	const [dealershipFilters, setDealershipFilters] = React.useState(["AUSTIN", "SUNDEK OF HOUSTON", "HOUSTON", "Sundek of Nashville", "SUNDEK NATIONAL ACCOUNTS", "Sundek of PA", "SAN ANTONIO", "SUNDEK OF WASHINGTON"]);

	const [ isHAModalOpen, setIsHAModalOpen ] = React.useState(false);
	const [ isJobModalOpen, setIsJobsModalOpen ] = React.useState(false);
	const [ isSetJobProcessedModalOpen, setIsSetJobProcessedModalOpen ] = React.useState(false);


	const [ invoiceUnderReferral, setInvoiceUnderReferral ] = React.useState(false);
	const [ itemizedInvoice, setItemizedInvoice ] = React.useState(false);
	const [ jobUnderReferralC, setJobUnderReferralC ] = React.useState(false);

	const [ opportunityNameFilter, setOpportunityNameFilter ] = React.useState('');

	const [ tmpJobId, setTmpJobId] = React.useState(null);
	const [ tmpDealershipFile_Id, setTmpDealershipFile_Id] = React.useState(null);
	const [ tmpOpportunity_no, setTmpOpportunity_no ] = React.useState(null);
	const [ opportunityTimeout, setOpportunityTimeout ] = React.useState(null);

	const tableDealershipFilters = [
		{ text: "AUSTIN", value: "AUSTIN" }, 
		{ text: "SUNDEK OF HOUSTON", value: "SUNDEK OF HOUSTON" }, 
		{ text: "HOUSTON", value: "HOUSTON" }, 
		{ text: "Sundek of Nashville", value: "Sundek of Nashville" }, 
		{ text: "SUNDEK NATIONAL ACCOUNTS", value: "SUNDEK NATIONAL ACCOUNTS" }, 
		{ text: "Sundek of PA", value: "Sundek of PA" }, 
		{ text: "SAN ANTONIO", value: "SAN ANTONIO" }, 
		{ text: "SUNDEK OF WASHINGTON", value: "SUNDEK OF WASHINGTON" }
	];
	

	const { setFilters, sorter, tableProps, tableQueryResult: { refetch } } = useTable({
		
		resource: 'jobs/list',
		sorters: {
			initial: [
				{
					field: 'Proposal__r.Go_Ahead_Date__c',
					order: 'desc'
				}
			]
		}
	});


	const dealershipsData = useSelect({
        optionLabel: 'sf_dealership',
        resource: 'dealerships'
    });

    const dealerships = dealershipsData?.queryResult?.data?.data.map(e => e.sf_dealership);




	React.useEffect(() => {
		if (dateFilters) {
			setFilters([
				{
					field: "startDate",
					value: dateFilters[0].unix(),
					operator: "eq"
				},
				{
					field: "endDate",
					value: dateFilters[1].unix(),
					operator: "eq"
				}
			]);
		} else if(!dateFilters){
			setFilters([
				{ field: "startDate", operator: "eq", value: null },
				{ field: "endDate", operator: "eq", value: null }
			]);
		}

	}, [dateFilters]);



	React.useEffect(() => {

		setFilters([
			{
				field: "Opportunity__r.Dealership_on_Account__c",
				value: dealershipFilters,
				operator: "in"
			}
		]);

	}, [dealershipFilters]);




	React.useEffect(() => {

		if(typeof opportunityTimeout === 'number'){
			clearTimeout(opportunityTimeout);
		}

		setOpportunityTimeout(setTimeout(() => {
		
			setFilters([
				{
					field: "Opportunity__r.OpportunityNumber__c",
					value: opportunityNameFilter,
					operator: "endswith"
				}
			]);

		}, 1500));


	}, [opportunityNameFilter]);



	const addDealership = (dealership) => {

		if(dealershipFilters.includes(dealership)){
			return;
		} else {
			let currentDealerships = dealershipFilters;
			currentDealerships.push(dealership);
			setDealershipFilters(currentDealerships);
		}

	}



	const displayHAModal = (job) => {

		setCurrentDisplayingJob(job);
		setIsHAModalOpen(true);

	}


	const displayJobInformation = (job) => {

		setCurrentDisplayingJob(job);
		setIsJobsModalOpen(true);

	}



	const displayReopenDialog = (jobId, dealershipFile_Id, opportunity_no) => {

		setTmpJobId(jobId);
		setTmpDealershipFile_Id(dealershipFile_Id);
		setTmpOpportunity_no(opportunity_no);

		show();

	}



	const displaySetJoProcessedDialog = (jobId, dealershipFile_Id, opportunity_no) => {

		setTmpJobId(jobId);
		setTmpDealershipFile_Id(dealershipFile_Id);
		setTmpOpportunity_no(opportunity_no);

		setIsSetJobProcessedModalOpen(true);

	}



	const handleCancel = () => {

		setTmpJobId(null);
		setTmpDealershipFile_Id(null);
		setTmpOpportunity_no(null);
		close();

	}


	const handleOk = () => {

		sendRequestToPersist(tmpJobId, tmpDealershipFile_Id, tmpOpportunity_no);

	}


	const handleRequestToSetJobProcessed = () => {

		sendAlreadyProcessedReq(tmpJobId, tmpDealershipFile_Id, tmpOpportunity_no);

	}


	const hideHAForm = () => {

		setCurrentDisplayingJob(null);
		setInvoiceUnderReferral(false);
		setItemizedInvoice(false);
		setIsHAModalOpen(false);

	}



	const hideJobInformation = () => {

		setCurrentDisplayingJob(null);
		setIsJobsModalOpen(false);

	}



	const hideSetJoProcessedDialog = () => {

		setTmpJobId(null);
		setTmpDealershipFile_Id(null);
		setTmpOpportunity_no(null);

		setIsSetJobProcessedModalOpen(false);

	}



	const removeDealership = (dealership) => {

		if(dealershipFilters.includes(dealership)){
			let currentDealerships = [...dealershipFilters];
			let idx = currentDealerships.indexOf(dealership);
			currentDealerships.splice(idx, 1);
			setDealershipFilters(currentDealerships);
		}
	}



	const { mutate } = useUpdate();


	function sendRequestToPersist(jobId, dealershipFile_Id, opportunity_no){

		mutate({
			resource: 'jobs/sendToQB',
			values: {
				dealershipFile_Id,
				invoiceUnderReferral,
				itemizedInvoice,
				jobUnderReferralC,
				jobId,
				opportunity_no
			},
			id: jobId,
			successNotification: (data, values, resource) => {
				return {
					message: `Data will be sent to quickbooks next time it connects to this app.`,
					description: `Successfully requested`,
					type: 'success'
				}
			}
		}, {
			onError: (error, variables, context) => {
				console.log(error);
				refetch();
			},
			onSuccess: (data, variables, context) => {
				setTmpDealershipFile_Id(null);
				setTmpJobId(null);
				setInvoiceUnderReferral(false);
				setItemizedInvoice(false);
				hideHAForm();
				close();
				refetch();
			}
		})

	}



	function sendAlreadyProcessedReq(jobId, dealershipFile_Id, opportunity_no){

		mutate({
			resource: 'jobs/storeAsProcessed',
			values: {
				dealershipFile_Id,
				invoiceUnderReferral,
				itemizedInvoice,
				jobUnderReferralC,
				jobId,
				opportunity_no
			},
			id: jobId,
			successNotification: (data, values, resource) => {
				return {
					message: `Data will be sent to quickbooks next time it connects to this app.`,
					description: `Successfully requested`,
					type: 'success'
				}
			}
		}, {
			onError: (error, variables, context) => {
				console.log(error);
				refetch();
			},
			onSuccess: (data, variables, context) => {
				setTmpDealershipFile_Id(null);
				setTmpJobId(null);
				setInvoiceUnderReferral(false);
				setItemizedInvoice(false);
				hideSetJoProcessedDialog();
				close();
				refetch();
			}
		})

	}


	return(

		<>

	        <List>

	            <Table { ...tableProps } rowKey="Id">

	                <Table.Column 
	                	dataIndex="Id"
	                	filtered
	                	filterDropdown={(filterDropdownProps) => {
							return (
								<FilterDropdown
									{...filterDropdownProps}
									setSelectedKeys={() => undefined}
									clearFilters={() => setOpportunityNameFilter('')}
								>
									
									<div>
										<Input
											maxLength={ 6 }
											onChange={ e => setOpportunityNameFilter(e.target.value?.replace(/\D/g,'')) }
											value={ opportunityNameFilter }
											
										/>
									</div>

								</FilterDropdown>
							);
						}}
	                	title="Opportunity #" 
	                	render={(_, record) => record.Opportunity__r.OpportunityNumber__c} />
	                <Table.Column 
	                	dataIndex="Account_Name__c" 
	                	
	                	title="Customer" />
	                <Table.Column dataIndex="Proposal_Job_Type__c" title="Proposal job type" />
	                <Table.Column dataIndex="Opportunity__r.Referral_Partner__r" title="Referral partner" render={ (_, record) => record.Opportunity__r.Referral_Partner__r?.Name ? record.Opportunity__r.Referral_Partner__r?.Name : null } />
	                <Table.Column 
	                	dataIndex="Opportunity__r" 
	                	filtered
	                	// filters={ tableDealershipFilters }
	                	filterDropdown={(props) => (
	                		<FilterDropdown 
	                			{ ...props }
	                			selectedKeys={ dealershipFilters }
	            			>
	                			<Select
	                				style={{  width: 300 }}
	                				mode="multiple"
	                				placeholder="Select dealerships"
	                				options={ tableDealershipFilters }
	            					onDeselect={ (val, option) => removeDealership(val) }
	            					onSelect={ (val, option) => addDealership(val) }
	        					/>
	                		</FilterDropdown>
	            		)}
	                	title="Dealership" render={ 

		                	(_, record) => {
		                		return record.Opportunity__r.Dealership_on_Account__c
		                	}

	                	} 
	            	/>
	                <Table.Column dataIndex="Customer_State__c" title="State" />
	                <Table.Column 
	                	dataIndex="Proposal__r.Go_Ahead_Date__c" 
	                	filtered={Boolean(dateFilters)}
						filterDropdown={(filterDropdownProps) => {
							return (
								<FilterDropdown
									{...filterDropdownProps}
									setSelectedKeys={() => undefined}
									clearFilters={() => setDateFilters(null)}
								>
									
									<div>
										<DatePicker.RangePicker
											value={dateFilters}
											onCalendarChange={(values) => {
												if (values?.length === 2) {
													setDateFilters([dayjs(values[0]), dayjs(values[1])]);
												}
											}}
										/>
									</div>

								</FilterDropdown>
							);
						}}
	                	render={ (_, record) => {  

	                		let dateChunks = record.Proposal__r?.Go_Ahead_Date__c?.split('-');

	                		if(!dateChunks || dateChunks.length === 0){

	                			return `N/A`;

	                		}

		                    let jobDate = new Date();
		                    jobDate.setUTCFullYear(dateChunks[0]);
		                    jobDate.setUTCMonth(parseInt(dateChunks[1]) - 1);
		                    jobDate.setUTCDate(dateChunks[2]);

		                    return `${ to2(jobDate.getMonth() + 1) }-${ to2(jobDate.getDate()) }-${ jobDate.getFullYear() }`;

		                } } 
		                sorter={{ multiple: 1 }}
		                defaultSortOrder={ getDefaultSortOrder("Proposal__r.Go_Ahead_Date__c", sorter) }
		                title="Sale date" />
	                <Table.Column dataIndex="persistedInQB" title="Already in QB" render={ (value) => value === 'PERSISTED_IN_QB' ? 'CREATED_IN_QB' : value || "DON'T KNOW" } />


	                <Table.Column dataIndex="lastSendToQBReq" title="Last send to QB request" render={ (value) => {

	                	if(!value) return null;

	                	if(value.length === 0)  return <center>N / A</center>;

	                	const history = <div>
	                		{
	                			value.map((v, i) => {
	                				
	                				return (
	                					<p key={ v } style={{ padding: '0 0 0.5rem 1rem', borderBottom: i < value.length -1 ? 'solid 1px #ccc' : 'none' }}>
	                						{ formatDate(v) }
	                					</p>
                					)
	                			})
	                		}
	                	</div>


	                	const contents = <>
	                		<Popover color="#292929" content={ history } title={ <center><span style={{ fontSize: '1rem', marginBotton: '0.75rem' }}>Request history</span></center> }>
	                			<span>
	                				{ formatDate(value[0]) }
	                			</span>
                			</Popover>
	                	</>;

	                	

	                	return contents;

	                } } />


	                <Table.Column dataIndex="createdInQBAt" title="Date created in QB" render={ (value, record) => {

	                	if(!value) return null;
	                	
	                	return record.persistedInQB !== 'WAITING_FOR_QB' ? formatDate(value) : null

	                } }/>
	                <Table.Column
	                    title="Actions"
	                    dataIndex="actions"

	                    render={ (_text, record) => (

	                    	<>

		                    	{
		                    		(dealerships?.includes(record.Opportunity__r.Dealership_on_Account__c) && !record.Opportunity__r.Referral_Partner__r?.Name) ?     	
		                    	
				                        <Space>
			
				                            {
				                                record.persistedInQB !== 'WAITING_FOR_QB' && record.persistedInQB !== 'PERSISTED_IN_QB' ?
				                                <Button icon={ <SendOutlined /> } onClick={ (e) => sendRequestToPersist(record.Id, dealershipsData?.queryResult?.data?.data.find(e => e.sf_dealership === record.Opportunity__r.Dealership_on_Account__c)?.id, record.Opportunity__r?.OpportunityNumber__c) } >Send to QB</Button> :
				                                <Button icon={ <RedoOutlined /> } onClick={ (e) => displayReopenDialog(record.Id, dealershipsData?.queryResult?.data?.data.find(e => e.sf_dealership === record.Opportunity__r.Dealership_on_Account__c)?.id, record.Opportunity__r?.OpportunityNumber__c) } >Re-Send to QB</Button>
				                            }
			
				                           
				                        </Space>
		
			                        : 

			                        (dealershipsData?.queryResult?.data?.data?.find(e => e.sf_dealership === record.Opportunity__r.Dealership_on_Account__c)?.house_accounts_enabled) ?

			                        	<Space>
			
				                            {
				                                record.persistedInQB !== 'WAITING_FOR_QB' && record.persistedInQB !== 'PERSISTED_IN_QB' ?
				                                <Button icon={ <SendOutlined /> } onClick={ (e) => displayHAModal(record) } >Send to QB</Button> :
				                                <Button icon={ <RedoOutlined /> } onClick={ (e) => displayReopenDialog(record.Id, dealershipsData?.queryResult?.data?.data.find(e => e.sf_dealership === record.Opportunity__r.Dealership_on_Account__c)?.id, record.Opportunity__r?.OpportunityNumber__c) } >Re-Send to QB</Button>
				                            }
			
				                           
				                        </Space>

			                        :

			                        null

			                    }

			                    {
			                    	dealerships?.includes(record.Opportunity__r.Dealership_on_Account__c) && record.persistedInQB !== 'PERSISTED_IN_QB' &&
		                    		<Space>
		                    			<Button icon={ <FileDoneOutlined /> } onClick={ (e) => displaySetJoProcessedDialog(record.Id, dealershipsData?.queryResult?.data?.data.find(e => e.sf_dealership === record.Opportunity__r.Dealership_on_Account__c)?.id, record.Opportunity__r?.OpportunityNumber__c) }>Set as stored in QB</Button>
		                    		</Space>
			                    }

			                     <Button icon={ <EyeOutlined /> } onClick={ (e) => displayJobInformation(record) } />

	                        </>

	                    )}
	                />


	            </Table>

	        </List>



	        <Modal 
	        	okText="Send to QB"
	        	onCancel={ hideHAForm }
	        	onOk={ e => sendRequestToPersist(currentDisplayingJob.Id, dealershipsData?.queryResult?.data?.data.find(e => e.sf_dealership === currentDisplayingJob.Opportunity__r.Dealership_on_Account__c)?.id, currentDisplayingJob.Opportunity__r?.OpportunityNumber__c) }
	        	open={ isHAModalOpen }
	        	title={ currentDisplayingJob ? <p style={{ borderBottom: 'solid 1px #666', fontSize: '1.1rem', paddingBottom: '0.5rem' }}><strong>House account invoice request -</strong> { currentDisplayingJob.Opportunity__r.OpportunityNumber__c }</p> : 'Loading...' }
	        	width={ 700 }
        	>

        		{
        			currentDisplayingJob && isHAModalOpen && 
        			<>
        				<Title level={5}>Account name</Title>
		    			<Text>{ currentDisplayingJob.Account_Name__c }</Text><br/>&nbsp;<br/>

		    			<Row>
		    				<Col span={ 12 }>
			        			<Title level={5}>Referral partner</Title>
				    			<Text>{ currentDisplayingJob.Opportunity__r.Referral_Partner__r.Name }</Text><br/>&nbsp;<br/>
				    		</Col>
				    		<Col span={ 12 }>
				    			<Title level={5}>Referral contact</Title>
				    			<Text>{ currentDisplayingJob.Opportunity__r.Referral_Contact__r?.Name ? currentDisplayingJob.Opportunity__r.Referral_Contact__r.Name : 'N / A' }</Text><br/>&nbsp;<br/>
				    		</Col>

		    			</Row>


		    			<Row>
		    				<Col span={ 12 }>
				    			<Checkbox checked={ invoiceUnderReferral } onChange={ e => setInvoiceUnderReferral(e.target.checked) }>
									Generate invoice under referral partner
								</Checkbox>
							</Col>

							{
								currentDisplayingJob.Opportunity__r.Referral_Contact__r?.Name &&
								<Col span={ 12 }>
									<Checkbox checked={ jobUnderReferralC } disabled={ !invoiceUnderReferral } onChange={ e => setJobUnderReferralC(e.target.checked) }>
										Generate job under referral contact
									</Checkbox>
								</Col>
							}
							

							<Col span={ 12 }>
								<Checkbox checked={ itemizedInvoice } onChange={ e => setItemizedInvoice(e.target.checked) }>
									Generate itemized invoice
								</Checkbox>
							</Col>

						</Row>

	    			</>
        		}
        		

	        </Modal>


	        <Modal 
	        	title="Are you sure?"
	        	open={ visible } onOk={ handleOk } onCancel={ handleCancel }
        	>

        		<Text>
        			You're about to request sending an Opportunity to quickbooks again. If it's an opportunity that generates invoices, there's a chance that the invoices will be generated again.
        		</Text>

	        </Modal>



	        <Modal 
	        	title="Are you sure?"
	        	open={ isSetJobProcessedModalOpen } onOk={ handleRequestToSetJobProcessed } onCancel={ hideSetJoProcessedDialog }
        	>

        		<Text>
        			You're about to request to set a job as already processed. If you're sure that you (or somebody else) already created the corresponding items for this job in Quickbooks click ok.
        		</Text>

	        </Modal>




	        <Modal 
	        	cancelButtonProps={{ style: { display: 'none' } }}
	        	footer={
	        		<Row justify="end">
	        			{ currentDisplayingJob && <Col span={ 4 }><Button type="primary" href={ `https://sundek.lightning.force.com/lightning/r/Opportunity/${ currentDisplayingJob.Opportunity__r.Id }/view` } target="_blank" >View in SF</Button></Col>}
	        			<Col span={ 4 }><Button onClick={ e=>hideJobInformation()}>Close</Button></Col>
	        		</Row>
	        	}
	        	okText="Close"
	        	okType="success"
	        	onCancel={ hideJobInformation }
	        	onOk={ hideJobInformation }
	        	open={ isJobModalOpen }
	        	title={ currentDisplayingJob ? <p style={{ borderBottom: 'solid 1px #666', fontSize: '1.1rem', paddingBottom: '0.5rem' }}><strong>Opportunity</strong> { currentDisplayingJob.Opportunity__r.OpportunityNumber__c }</p> : 'Loading...' }
	        	width={ 700 }
        	>

        		{
        			currentDisplayingJob &&
        			<>
        				<Row gutter={[12, 40]}>
        					<Col span={ 12 }>
			        			<Title level={5}>Account name</Title>
			        			<Text>{ currentDisplayingJob.Account_Name__c }</Text><br/>&nbsp;<br/>
		        			</Col>
		        			{
		        				currentDisplayingJob.CustomerContactLastName__c &&
		        				<Col span={ 12 }>
			        				<Title level={ 5 }>Customer's name</Title>
			        				<Text>{ currentDisplayingJob.CustomerContactLastName__c }, { currentDisplayingJob.CustomerContactFirstName__c }</Text>
		        				</Col>
		        			}
		        		</Row>

		        		<Row gutter={[12, 48]}>
		        			<Col span={ 24 }>
			        			<Title level={ 5 } >Job type</Title>
			        			<Text>{ currentDisplayingJob.Proposal_Job_Type__c }</Text><br/>&nbsp;<br/>
			        		</Col>
		        		</Row>

		        		<Row gutter={[12, 48]}>
		        			<Col span={ 12 }>
			        			<Title level={ 5 } >Location</Title>
			        			<Text>{ currentDisplayingJob.CustomerContactCity__c }, { currentDisplayingJob.Customer_State__c }</Text><br/>&nbsp;<br/>
		        			</Col>
		        			<Col span={ 12 }>
			        			<Title level={ 5 }>Dealership</Title>
			        			<Text>{ currentDisplayingJob.Opportunity__r.Dealership_on_Account__c }</Text>
		        			</Col>
	        			</Row>

	        			<Row gutter={[12, 48]}>
	        				<Col span={ 12 }>
			        			<Title level={ 5 }>Vendor name</Title>
			        			<Text>{ currentDisplayingJob.Opportunity__r.Owner.Name }</Text><br/>&nbsp;<br/>
		        			</Col>
		        			<Col span={ 12 }>
			        			<Title level={ 5 }>Record type</Title>
			        			<Text>{ currentDisplayingJob.Opportunity__r.RecordType.Name }</Text>
		        			</Col>
	        			</Row>

	        			<Divider orientation="left" />

	        			{
	        				currentDisplayingJob.persistedInQB &&
	        				<Row gutter={[12, 48]}>
	        					<Col span={ 7 }>
		        					<Title level={ 5 }>QB status</Title>
		        					<Text>{ currentDisplayingJob.persistedInQB }</Text><br/>&nbsp;<br/>
	        					</Col>
	        					<Col span={ 9 }>
		        					<Title level={ 5 }>Last time requested</Title>
		        					<Text>{ currentDisplayingJob.lastSendToQBReq.length > 0 ? formatDate(currentDisplayingJob.lastSendToQBReq[0]) : 'N / A'}</Text>
	        					</Col>
	        					<Col span={ 7 }>
		        					<Title level={ 5 }>Persisted at: </Title>
		        					<Text>{ currentDisplayingJob.persistedInQB !== 'WAITING_FOR_QB' ? formatDate(currentDisplayingJob.createdInQBAt) : '--' }</Text>
	        					</Col>
	        				</Row>
	        			}

        			</>
        		}

	        </Modal>


        </>


    );

}